import React from 'react';
import './src/styles/global.scss';

export function onRouteUpdate() {
  setTimeout(() => {
    window.uetq = window.uetq || [];
    window.uetq.push('event', 'page_view', { page_path: window.location.pathname });
    window.uetq.push('event', '', { ecomm_prodid: '1', ecomm_pagetype: 'other' });

    if (typeof window.fbq === 'function') {
      window.fbq('track', 'ViewContent');
    }
  }, 50);
}
